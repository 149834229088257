import React, { createElement } from 'react';
import {  getItemSession } from '../../../utils'
import { useLanguage } from '../../../LanguageContext';

const MenuPrincipal = () => { 
    const { translations, changeLanguage } = useLanguage();
    const login = JSON.parse(getItemSession('_dados'));

    return (
        <div className="header_nav_cont center" id="menuPrincipal">
            <h4 className="titulo h4">Menu principal</h4>
            <nav className="nav">
                <ul className="list-unstyled mb-0">
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao ? <a className="nav-link" href="/minha-conta">{translations.DropdownUsers?.Minha_Conta}</a> : ' '} 
                    </li>
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao && <a className="nav-link" href="/pedidos">{translations.DropdownUsers?.Pedidos}</a>}
                    </li>
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao && <a className="nav-link" href="/pendencias">translations.DropdownUsers?.Pendencias</a>}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/busca?t=", className: "nav-link" }, translations.MenuPrincipal?.Categorias)} 
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine1", className: "nav-link" },  translations.MenuPrincipal?.Especiales)}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine2", className: "nav-link" },  translations.MenuPrincipal?.Noticias)} 
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine3", className: "nav-link" },  translations.MenuPrincipal?.Lanzamientos)}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine4", className: "nav-link" }, translations.MenuPrincipal?.Productos_Increibles)}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine5", className: "nav-link" }, translations.MenuPrincipal?.Promociones)}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/service-center", className: "nav-link" }, translations.MenuPrincipal?.Service_Center)}
                    </li>
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao && <a role="button" href="#carrinho" className="nav-link nav-dropdown">{translations.MenuPrincipal?.Carrinho}<span className="fa fa-chevron-right"></span></a> }
                    </li>
                    <br/>
                    <li className="nav-item">
                        {login?.ApenasVisualizacao ? <a className="nav-link" href='/logout'>Login</a> : <a className="nav-link" href="/login_vitrine">{translations.DropdownUsers?.logout}</a>}
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default MenuPrincipal;