import React, { useState, useEffect } from 'react';
import Category from './Category/Category';
import { getAllCategories } from '../../containers/CategoriesContainer';
import { useLanguage } from '../../LanguageContext';
const NavBar = () => {
    const [allCategories, setAllCategories] = useState([]);
    const { translations, changeLanguage } = useLanguage();
    
    useEffect(() => {
        const fetchData = async () => {
            const categories = await getAllCategories();
            setAllCategories(categories);
        };

        fetchData();
    }, []);

    let propsNull = {
        id: null,
        descricao: translations.MenuPrincipal?.Categorias
    };
    return (
        <div className="header_nav d-none d-sm-flex row m-0 align-items-center">
            <nav className="nav col-11 col-xl-12 p-0 position-relative">
                <ul className="list-unstyled m-0 col-sm-3 p-0">
                    <Category props={propsNull} />
                    <li className="nav-item col-lg-6 m-0 p-0">
                        <a role="button" href="/destaque/Vitrine5" className="nav-link px-0 pr-4 justify-content-center">{translations.NavBar?.Promociones}</a>
                    </li>
                </ul>
                <ul className="list-unstyled mb-0 col-lg-9 p-0 display-block">
                    {allCategories
                        .filter(newarr => newarr.idClassificacaoNivelAnterior == null)
                        .slice(0, 6)
                        .map(category =>{
                            return <Category key={`cat-class-${category.idClassificacaoNivel}`} props={{
                                id: category.idClassificacaoNivel,
                                descricao: category.Descricao
                            }} />
                        })}
                </ul>
            </nav>
        </div>
    )
}

export default NavBar;