export const arrOrigin = {
    'home_promocao': 'Home [Promoção]',
    'home_especiales': 'Home [Especiales]',
    'home_noticias': 'Home [Noticias]',
    'home_lancamento': 'Home [Lançamentos]',
    'home_revista': 'Home [Itens Mais Vendidos]',
    'home_marcapropria': 'Home [Marca Própria]',
    'pagina_promocao': 'Página [Promoção]',
    'pagina_Promociones': 'Página [Promoção]',
    'pagina_lancamento': 'Página [Lançamentos]',
    'pagina_': 'Página [Lançamentos]',
    'pagina_revista': 'Página [Itens Mais Vendidos]',
    'pagina_marcapropria': 'Página [Marca Própria]',
    'pagina_queimaestoque': 'Página [Promociones]',
    'pagina_fornecedor': 'Página [Fornecedor]',
    'pagina_categoria': 'Página [Categoria]',
    'pagina_busca': 'Página [Busca]',
    'pagina_Especiales': 'Home [Especiales]',
    'pagina_Noticias': 'Home [Noticias]',
    'pagina_Productos Increibles': 'Home [Productos Increibles]',
    'pagina_produto': 'Página [Detalhe do Produto]',
    'pagina_produto_incrementar': 'Página [Detalhe do Produto bloco Incrementar]',
    'pagina_carrinho_incrementar': 'Página [Carrinho bloco Incrementar]'
}

export const arrHighlights = {
    'Vitrine1': {
        titulo: 'Especiales'
    },
    'Vitrine2': {
        titulo: 'Noticias'
    },
    'Vitrine3': {
        titulo: 'Lanzamientos'
    },
    'Vitrine4': {
        titulo: 'Productos Increibles'
    },
    'Vitrine5': {
        titulo: 'Promociones'
    }
};


export const trackingIcons = {
    'C-1': 'icon-box', // Gerado
    'C-2': 'icon-doc', // Em Análise
    'C-3': 'icon-shopping-basket', // Aprovado
    'C-4': 'icon-box-open', // Em Separação
    'C-5': 'icon-invoice', // NF Emitida
    'C-6': 'icon-truck', // Em Rota
    'C-7': 'icon-check-2', // Entregue 
    'C-8': 'icon-doc-excel', // Cancelado
    'C-9': 'icon-doc-excel', // Devolvido
    'C-10': 'icon-doc-excel', // Em atualização
    'Rejeitado': 'icon-doc-excel',
    'Excluido': 'icon-doc-excel',
}
